import { APP_TYPE, AppType } from "../app.type";
import { BehaviorSubject } from "rxjs";
import { Customer } from "../../../../shared/types/customers";
import { DocModel } from "../state/state";
import { EncodingUtils } from "../../../../shared/utils/encoding.utils";
import { FieldServiceType } from "./field.service.type";
import { Inject, Injectable } from "@angular/core";
import { Part } from "../../../../shared/models/part";
import { Post } from "../../../../shared/models/post";
import { Responsibles } from "../../../../shared/models/responsibles";
import { StringUtils } from "../../../../shared/utils/string.utils";
import { Thread } from "../../../../shared/models/thread";
import { Type } from "../../../../shared/components";
import { UserOptions } from "../../../../shared/models/user";
import {
  FieldType,
  _ComponentSettings,
} from "../../../../shared/settings/settings";
import {
  MineralStatus,
  Manufacturer,
} from "../../../../shared/models/manufacturer";
import {
  NON_RM_CLIENT,
  SPECIAL_CONFIGURATION,
  TRANSPORT_CLIENT,
  USUAL_CONFIGURATION,
} from "../customers/customers.service";
import {
  deleteFile,
  getManufacturerCodeByName,
  Search,
  getManufacturerNameByCode,
  getCageCodeList,
} from "../api.service";
import {
  ObsolescenceStatus,
  ObsolescencelifeCycleRisk,
  EuRoHSStatus,
  ReachAffectedStatus,
} from "../../../../shared/models/rm-common-properties";

@Injectable()
export class FieldService implements FieldServiceType {
  displayCassesPerCpn = false;
  displayCassesPerMpn = false;
  displayCassesPerPcnId = false;

  invalidRegex = false;

  inputSubject = new BehaviorSubject<boolean>(false);

  isMultipleSelected = false;

  currentFieldType = "";
  currentFieldRequreired: any;

  defaultSettings: any[] = [];

  manufacturerNames: string[] = [];
  manufacturerCodes: string[] = [];
  cageCodeList: string[] = [];

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  set current(field: string | null) {
    this.app.state.next({ field, featureChart: null });
  }

  get current() {
    return this.app.state.field;
  }

  getLabel(id: string): string {
    try {
      return this.app.getTranslatedText(this.getFieldSettings(id).text);
    } catch (err) {
      throw err;
    }
  }

  handleSpecialLogicLabels(id: string): string {
    switch (id) {
      case "manufacturer.rohs":
        return this.app.text.matchingManufacturer.rohs;
      case "manufacturer.estimatedYearsToEOL":
        return this.app.text.RM.yteop;
      case "select":
        return this.app.text.matchingManufacturer.select;
      case "datasheet":
        return this.app.text.matchingManufacturer.datasheet;
      case "thread.vehicleNames":
        if (this.app.customers.expectCurrent === Customer.DB) {
          return this.app.field.getLabel("thread.vehicles");
        }

        if (this.app.customers.expectCurrent === Customer.NS) {
          return this.app.text.train.trainseries;
        }

        break;
      case "manufacturer.createManufacturer":
        return this.app.text.manufacturer.createNewManufacturer;
      case "seen":
        return this.app.field.getLabel("change.seen");
      case "delete":
        return this.app.text.app.delete;
      case "createCase":
        return this.app.text.alert.createThread;
      case "part.threadCase":
        if (this.app.permission.RM.isAnalyst || this.app.auth.isRMAdmin) {
          return "";
        }

        return "<i class='fas fa-folders' aria-hidden='true'></i>";
      case "part.matchedSystem":
        return "<i class='fas fa-database' aria-hidden='true'></i>";
      case "part.statusAlerts":
        return "<i class='fa-regular fa-message-exclamation' aria-hidden='true'></i>";
      case "part.partNumber":
        if (this.app.view === "RM") {
          return `${
            "<i class='fas fa-sitemap' aria-hidden='true'></i>&nbsp;&nbsp;&nbsp;" +
            this.app.field.getLabel(id)
          }`;
        }

        return this.app.field.getLabel(id);
      case "part.detailView":
      case "part.partsToVehicles":
        return "";
      case "part.obsolescenceManagement":
        return "";
      case "manufacturer.materialDeclaration":
        return this.app.text.manufacturer.link;
      case "alert.delete":
        return "Delete / select all";
      case "remove":
        return this.app.text.part.remove;
      case "manufacturer.supplierPending":
        return this.app.text.manufacturer.pending;
      case this.app.fieldId.part.lastNote:
        return this.app.text.message.note;
      case "part.description":
        if (this.app.RM.isTree) {
          return this.app.text.RM.designationColumn;
        }
        return this.app.field.getLabel(id);
      case "manufacturer.manufacturerPartNumber":
      case "alert.manufacturerPartNumber":
      case "alert.partNumber":
      case "alert.severity":
      case "pcn.artNumber":
      case "pcn.crtNumber":
      case "pcn.oDate":
      case "pcn.creator":
      case "pcn.pcnID":
      case "pcn.changeClasses":
      case "pcn.itemEOP":
      case "pcn.omfShortDescr":
      case "thread.artNumber":
      case "change.omfShortDescr":
      case "part.quantity":
      case "part.sapStatus":
      case "manufacturer.sapStatus":
      case "manufacturer.estimatedEOLDate":
      case "manufacturer.ltbDate":
      case "manufacturer.maxWeeks":
      case "manufacturer.origin":
      case "manufacturer.averagePrice":
      case "manufacturer.totalMarketInv":
      case "manufacturer.numberOfDistributors":
      case "manufacturer.countryOfOrigin":
        return this.getTableLabel(id);
      default:
        return this.app.field.getLabel(id);
    }

    return "";
  }

  getHelperText(id: string): string {
    const text = this.getFieldSettings(id).helperText;
    if (text == null) {
      return "";
    } else {
      return this.app.getTranslatedText(text);
    }
  }

  getTooltip(id: string): string {
    const tooltip = this.getFieldSettings(id).tooltip;
    if (tooltip != null) {
      return this.app.getTranslatedText(tooltip);
    } else {
      return "";
    }
  }

  getAll(type: Type) {
    return Object.keys(this.app.settings[type].field).map(
      (field) => type + "." + field
    );
  }

  getValue(fieldId: string, docId: string | null) {
    if (docId == null) {
      return;
      // throw new Error("doc id expected");
    }
    const [type, element] = fieldId.split(".");
    const doc = this.app.docs.getDoc(docId, type as Type);
    return (doc as any)[element];
  }

  getValueAsText(fieldId: string, docId: string | null): string {
    const value = this.getValue(fieldId, docId);
    return this.getFieldValueAsText(fieldId, value);
  }

  getFieldValueAsText(fieldId: string, value: any): string {
    const field = this.getFieldSettings(fieldId);
    if (StringUtils.isNullOrEmpty(value)) {
      return "";
    }
    switch (field.type) {
      case "date":
        try {
          return new Date(value).toISOString().split("T")[0];
        } catch (err) {
          console.log(
            "invalid date value in field " +
              fieldId +
              ": " +
              JSON.stringify(value)
          );
          return "";
        }
      case "timestamp":
        let iso = new Date(value);
        const offset = new Date().getTimezoneOffset();
        iso = new Date(iso.getTime() - offset * 60 * 1000);
        return iso
          .toISOString()
          .replace("T", " ")
          .replace("Z", "")
          .split(".")[0];

      // for radio buttons return the option text not the value
      case "radio":
        return this.app.field.getOptionText(fieldId, value);
      case "checkbox":
        return this.app.field.getCheckboxLabelText(fieldId, value);
      case "options":
        // special case for user roles (this is not a multiple field, even though we store more values in it)
        if (
          field.multiple === true ||
          fieldId === this.app.fieldId.user.roles
        ) {
          return value
            .map((v: string) => this.getOptionText(fieldId, v))
            .sort()
            .join(", ");
        }
        return this.getOptionText(fieldId, value);
      default:
        return "" + value;
    }
  }

  hasValue(fieldId: string, docId: string) {
    const value = this.getValue(fieldId, docId);
    return this.isValue(value);
  }

  isValue(value: any) {
    if (StringUtils.isNullOrEmpty(value) || value.length === 0) {
      return false;
    }
    return true;
  }

  getType(id: string) {
    if (this.getFieldSettings(id) != null) {
      return this.getFieldSettings(id).type;
    } else {
      return "value";
    }
  }

  isMultiple(id: string) {
    return this.getFieldSettings(id).multiple === true;
  }

  isMutable(fieldId: string) {
    return this.getFieldSettings(fieldId).mutable !== false;
  }

  isRequired(id: string) {
    return this.getFieldSettings(id).required === true;
  }

  isUnique(id: string) {
    return this.getFieldSettings(id).unique === true;
  }

  isVisibleInFilter(id: string) {
    try {
      return this.getFieldSettings(id).search !== false;
    } catch (err) {
      return false;
    }
  }

  isValidRegex(field: string, value: string) {
    const pattern = this.getFieldSettings(field).regex;
    if (pattern != null && value != null) {
      const regex = new RegExp(pattern);
      if (value == null || value === "") {
        this.invalidRegex = false;
        return true;
      }
      if (!value.match(regex)) {
        this.invalidRegex = true;
        return false; // Invalid regex
      }
    } else {
      this.invalidRegex = false;
    }
    return true;
  }

  hasRegex(field: string) {
    if (this.app.field.getFieldSettings(field).regex != null) {
      return true;
    } else {
      return false;
    }
  }

  getOptions(id: string): string[] {
    const { options } = this.getFieldSettings(id);

    /** list of users based on the roles in order to be set as input options in the desired fields */
    const userArray = this.app.users.getOptions("user");
    const adminArray = this.app.users.getOptions("admin");
    const teamArray = this.app.users.getOptions("team");
    const guestArray = this.app.users.getOptions("guest");

    let allUsers = userArray.concat(adminArray, teamArray, guestArray);
    let uniqueUsers = [...new Set(allUsers)];

    const persons = this.sortSimpleStringsAlphabetically(
      this.app.person.getPersonOptions()
    );

    // build categories options array to be displayed in dropdown
    if (id === "thread.productCategory") {
      if (
        this.app.customer === Customer.COOP &&
        this.app.productCategory.categoriesInDropdown.length > 0
      ) {
        const categories = [
          ...new Set(this.app.productCategory.categoriesInDropdown),
        ];
        const categoriesOptions = [];

        for (const category of categories) {
          categoriesOptions.push(category);
        }

        return categoriesOptions;
      }
    }

    /** get list of field options for the applicationArea field */
    if (id === this.app.fieldId.thread.applicationArea) {
      const appAreaOptions = this.app.applicationArea.applicationAreaOptions;
      return [...new Set(appAreaOptions)];
    }

    if (options != null && Object.keys(options).length > 0) {
      // create an array of options for sorting
      const optionsArray = Object.entries(options);
      if (this.getFieldSettings(id).sort === true) {
        const optionsForSort: OptionList[] = [];
        for (const option of optionsArray) {
          optionsForSort.push({
            value: option[0],
            text: this.app.field.getOptionText(id, option[0]),
          });
        }
        return this.sortOptionsAlphabetically(optionsForSort);
      }
      if (
        (id === this.app.fieldId.thread.omfStatus ||
          id === this.app.fieldId.change.omfStatus) &&
        this.app.customers.expectCurrent === Customer.DB
      ) {
        /** exception rule for db, because of their statuses are 80,90, 90a, 90b,90c, 100 etc */
        const items: string[] = [];
        Object.keys(options).forEach((key) => {
          items.push(key);
        });
        items.sort((a, b) =>
          a.localeCompare(b, navigator.languages[0] || navigator.language, {
            numeric: true,
            ignorePunctuation: true,
          })
        );
        return items;
      }

      /** exception added for the thread.omApproach since it has to have the "No approach selected" option only for home filter  */
      if (
        this.app.customers.expectCurrent === Customer.MTU &&
        id === this.app.fieldId.thread.omApproach &&
        this.app.view === "thread"
      ) {
        delete options["noApproach"];
      }
      return Object.keys(options);
    }

    // populate the mrce stakeholder field with all users
    if (
      (this.app.customer === Customer.MRCE &&
        (id === this.app.fieldId.thread.stakeholder ||
          id === this.app.fieldId.thread.dinCodeRespName)) ||
      (this.app.customer === Customer.BVG &&
        id === this.app.fieldId.thread.teamMemberName)
    ) {
      allUsers = userArray.concat(adminArray, teamArray, guestArray);
      uniqueUsers = [...new Set(allUsers)];

      return Array.from(uniqueUsers);
    }
    if (id === "SVHCItems.casNumber") {
      return this.app.SVHCItems.casNumberOptions;
    }
    if (id === "SVHCItems.substanceIdentification") {
      return this.app.SVHCItems.identificationOptions;
    }

    if (this.app.customer === Customer.NS) {
      /** set contact data for ns */
      if (
        id === this.app.fieldId.thread.teamMemberName ||
        id === this.app.fieldId.thread.plannerName
      ) {
        return userArray.concat(guestArray);
      }

      if (id === this.app.fieldId.thread.dinCodeRespName) {
        /** users with jobTitle = gateKeeper */
        const gateKeepers = this.app.users.getOptions(undefined, "gateKeeper");

        /** users with jobTitle = OMContact */

        const omContacts = this.app.users.getOptions(undefined, "OMContact");
        return gateKeepers.concat(omContacts);
      }
      if (id === this.app.fieldId.thread.supplyManagerName) {
        /** return list of users with jobTitle  = supplyChainManager */

        return this.app.users.getOptions(undefined, "supplyChainManager");
      }
      if (id === this.app.fieldId.thread.buyerName) {
        /** return list of users with jobTitle = buyer */
        return this.app.users.getOptions(undefined, "buyer");
      }
      if (id === this.app.fieldId.thread.sysEngineerName) {
        /** return list of users with jobTitle = systemEngineer */
        return this.app.users.getOptions(undefined, "systemEngineer");
      }
      if (id === this.app.fieldId.thread.otherStakeholder) {
        /** created separate lists because this one needs to contain the list of analysts users as well */
        allUsers = userArray.concat(guestArray, teamArray, adminArray);
        uniqueUsers = [...new Set(allUsers)];

        return Array.from(uniqueUsers);
      }
    }
    if (this.app.customer === Customer.CEOTRONICS) {
      if (
        id === this.app.fieldId.thread.dinCodeRespName ||
        id === this.app.fieldId.thread.engineerName ||
        id === this.app.fieldId.thread.buyer
      ) {
        return allUsers;
      }
    }
    if (this.app.customer === Customer.MTU) {
      if (id === this.app.fieldId.thread.engineerName) {
        return allUsers;
      }
    }

    if (this.app.customer === Customer.DB) {
      const responsiblesKeys = Object.keys(new Responsibles()).map(
        (r) => (r = "responsibles." + r)
      );

      if (responsiblesKeys.includes(id)) {
        return userArray;
      }

      if (id === "thread.dinCodeRespName" || id === "thread.deputy") {
        return teamArray;
      }

      if (id === "thread.componentResponsible") {
        return this.app.users.componentResponsibles;
      }
    }
    if (this.app.customer === Customer.MAQUETCARDIO) {
      if (id === "thread.dinCodeRespName") {
        return teamArray;
      }
      if (id === "thread.buyer") {
        return uniqueUsers;
      }
    }
    if (this.app.customer === Customer.RHEINBAHN) {
      if (
        id === this.app.fieldId.thread.indicatorName ||
        id === this.app.fieldId.thread.dinCodeRespName ||
        id === this.app.fieldId.thread.materialPlaner
      ) {
        return persons;
      }
    }

    if (this.app.customer === Customer.KARLSTORZ) {
      if (
        id === this.app.fieldId.thread.indicatorName ||
        id === this.app.fieldId.thread.buyer ||
        id === this.app.fieldId.thread.omfCommodityRespName ||
        id === this.app.fieldId.thread.engineerName
      ) {
        return uniqueUsers;
      }
    }

    if (this.app.customer === Customer.RHEINMETALLAIRDEFENCE) {
      if (
        id === this.app.fieldId.thread.engineerName ||
        id === this.app.fieldId.thread.buyer
      ) {
        return userArray;
      }
    }

    if (id === "responsibles.responsibles") {
      const responsibles = this.app.users.userRoles.filter((u: UserOptions) =>
        u.roles.includes("db-user")
      );
      return responsibles.map((user: any) => user.name);
    }

    if (this.app.customers.getCustomerType() === TRANSPORT_CLIENT) {
      switch (id) {
        case this.app.fieldId.thread.omfCommodityId:
          return this.app.commodityGroupResponsible.commodityIds;
        case this.app.fieldId.thread.dinCodeRespName:
          return this.app.dinCodeResponsible.dinCodeRespName;
        case this.app.fieldId.thread.dinCodeRespDep:
          return this.app.dinCodeResponsible.dinCodeRespDep;
        case this.app.fieldId.thread.dinCodeRespEmail:
          return this.app.dinCodeResponsible.dinCodeRespEmail;
      }
    }

    if (this.app.permission.post.hasTasks) {
      if (id === this.app.fieldId.post.taskResponsible) {
        return [
          ...new Set(userArray.concat(guestArray, teamArray, adminArray)),
        ];
      }
    }

    if (id === this.app.fieldId.post.solutionImpacts) {
      if (this.app.impacts.impacts.length > 0) {
        return this.app.impacts.createImpactsArray(this.app.impacts.impacts);
      }
    }

    if (id === this.app.fieldId.train.trainResponsible) {
      return userArray.concat(adminArray, teamArray);
    }

    if (
      id === this.app.fieldId.impact.omfVehicleName &&
      (this.app.customers.getRMCustomers() === NON_RM_CLIENT ||
        this.app.customers.getSpecialCustomers() === SPECIAL_CONFIGURATION)
    ) {
      if (this.app.view === "home") {
        const result = this.app.impacts.impactsCompressed.map(
          (a) => a.omfVehicleName
        );
        const uniqueNames = [...new Set(result)];
        return uniqueNames;
      } else {
        return this.app.vehicleResponsible.vehicleNames;
      }
    }
    if (id === this.app.fieldId.train.trainName) {
      if (this.app.view === "thread") {
        const trainNames = Array.from(this.app.train.getOptions(id).keys());
        return this.sortSimpleStringsAlphabetically(trainNames);
      }
      if (this.app.view === "home") {
        const trainNames = this.app.train.trainsCompressed.map(
          (t) => t.trainName
        );
        return this.sortSimpleStringsAlphabetically(trainNames);
      }
    }

    if (id === this.app.fieldId.thread.dinCodeRespName) {
      return teamArray;
    }

    if (id === this.app.fieldId.thread.componentResponsible) {
      return userArray;
    }
    if (id === this.app.fieldId.manufacturer.partNumber) {
      return this.app.part.partsWithoutCpn;
    }

    return [];
  }

  getTypeAheadOptions(field: string) {
    let result: string[] = [];
    if (this.app.customers.getSpecialCustomers() === USUAL_CONFIGURATION) {
      const crtNumber = this.app.model["thread.crtNumber"];
      const artNumber = this.app.model["thread.artNumber"];
      if (this.app.customers.expectCurrent === Customer.BVG) {
        return [];
      }
      /** for each field of type responsibles, the list needs to be the same */
      if (field.split(".")[0] === "responsibles") {
        field = "responsibles.bavFv";
      }
      switch (field) {
        case "thread.crtNumber":
          if (this.app.view === "home") {
            result = this.app.filterList.mpns;
          } else {
            if (
              (StringUtils.isNullOrEmpty(crtNumber) &&
                StringUtils.isNullOrEmpty(artNumber)) ||
              !this.app.manufacturer.checkIfCpnMpnExists(
                artNumber,
                "thread.artNumber"
              )
            ) {
              result = this.app.manufacturer.manufacturerPartNumbers;
            } else {
              result = this.app.manufacturer.correspondingMpns;
            }
          }
          break;
        case "thread.artNumber":
          if (this.app.view === "home") {
            result = this.app.filterList.cpns;
          } else {
            if (
              (StringUtils.isNullOrEmpty(artNumber) &&
                StringUtils.isNullOrEmpty(crtNumber)) ||
              !this.app.manufacturer.checkIfCpnMpnExists(
                crtNumber,
                "thread.crtNumber"
              )
            ) {
              result = this.app.part.partNumbers;
            } else {
              result = this.app.manufacturer.correspondingCpns;
            }
          }
          break;
        case "thread.pcnID":
          result = this.app.filterList.pcnIds;
          break;
        case "thread.omfNumber":
          result = this.app.filterList.omfNumbers;
          break;
        case "responsibles.bavFv":
          result = this.app.users.userRoles.map(
            (user: UserOptions) => user.name
          );
          break;
        case "RM.searchField":
          if (
            this.app.RMSearch.filterMode === "part.partNumber" ||
            this.app.RMSearch.filterMode ===
              "manufacturer.manufacturerPartNumber"
          ) {
            result = this.app.RMSearch.result;
          } else {
            result = [];
          }
          break;
        case "manufacturer.manufacturerPartNumber":
          result = this.app.filterHeaderTable.manufacturersListSearchResult;
          break;
        case "manufacturer.partNumber":
          result = this.app.filterHeaderTable.partsListSearchResult;
          break;
        case "manufacturer.name":
          result = this.app.filterHeaderTable.nameSearchResult;
          break;
        case "part.partNumber":
          if (this.app.view === "part") {
            result = this.app.part.bomTypeaheadOptions;
          } else {
            result = this.app.filterHeaderTable.partsListSearchResult;
          }
          break;
        case "thread.creator":
          result = this.manufacturerNames;
          break;
      }
    }
    return result;
  }

  async getTypeaheadOptionsBasedOnType(
    type: string,
    field: string,
    value: string
  ) {
    // let selectedFilter = this.app.filterHeaderTable.filterValues;
    // let selectedFilter: any = {};
    let docType = type;
    let property = field;
    if (type === "thread" && this.app.view !== "home") {
      if (field === "artNumber") {
        docType = "nonAssembly";
        property = "partNumber";
      }
      if (field === "crtNumber") {
        docType = "manufacturer";
        property = "manufacturerPartNumber";
      }
      if (field === "creator") {
        if (this.app.customers.expectCurrent === Customer.KNDS) {
          docType = "manufacturerCode";
          property = "manufacturerName";
        } else {
          docType = "manufacturer";
          property = "name";
        }
      }
      if (field === "cageCode") {
        docType = "manufacturerCode";
      }
    }

    if (type === "RM") {
      docType = this.app.RMSearch.selectedType;
      property = this.app.RMSearch.filterMode.split(".")[1];
    }

    if (type === "part") {
      if (
        this.app.RM.mode === "tree" &&
        this.app.view != "part" &&
        field === "partNumber"
      ) {
        docType = "assembly";
      }
    }

    if (type === "manufacturer") {
      if (
        (this.app.view === "external-data-filter" ||
          this.app.view === "part") &&
        field === "partNumber"
      ) {
        docType = "nonAssembly";
      }
    }

    let selectedFilter: any = {};
    selectedFilter[property] = value;
    if (Object.keys(this.app.filterHeaderTable.filterValues).length > 0) {
      selectedFilter = {
        ...this.app.filterHeaderTable.filterValues,
        ...selectedFilter,
      };
    }
    return await Search(
      this.app.customers.expectCurrent,
      docType,
      selectedFilter,
      value
    );
  }

  sortSimpleStringsAlphabetically(array: string[]) {
    // replace german letters so the sorting can be done correctly
    const format = (s: string): string =>
      s
        .toLowerCase()
        .replace(/ä/g, "a")
        .replace(/ö/g, "o")
        .replace(/ü/g, "u")
        .replace(/ß/g, "ss");

    const sorted = array.sort((a: any, b: any) => {
      const textA = format(a);
      const textB = format(b);
      if (textA < textB) {
        return -1;
      }
      if (textA > textB) {
        return 1;
      }
      return 0;
    });
    return sorted;
  }

  sortOptionsAlphabetically(array: any) {
    // replace german letters so the sorting can be done correctly
    const format = (s: string): string =>
      s
        .toLowerCase()
        .replace(/ä/g, "a")
        .replace(/ö/g, "o")
        .replace(/ü/g, "u")
        .replace(/ß/g, "ss");

    // sort the array depenging on the text
    const sorted = array.sort((a: any, b: any) => {
      const textA = format(a.text);
      const textB = format(b.text);
      if (textA < textB) {
        return -1;
      }
      if (textA > textB) {
        return 1;
      }
      return 0;
    });

    // create an array of sorted keys
    const orderedKeysOptions = [];
    for (const key of sorted) {
      orderedKeysOptions.push(key.value);
    }

    return orderedKeysOptions;
  }

  getHelpTextArray(value: string): any {
    // List of texts is stored in "long" text of each option for resolveClass
    return value.split("\n");
  }

  getOptionText(id: string, value: string) {
    try {
      const { options } = this.getFieldSettings(id);
      if (options == null) {
        throw new Error("no options");
      }
      const result = options[value];
      if (result == null) {
        throw new Error("no value");
      }
      return this.app.getTranslatedText(result.text);
    } catch (err) {
      return value;
    }
  }
  getCheckboxLabelText(id: string, value: string) {
    try {
      const { checkboxLabels } = this.getFieldSettings(id);
      if (checkboxLabels == null) {
        throw new Error("no options");
      }
      const result = checkboxLabels[value];
      if (result == null) {
        throw new Error("no value");
      }
      return this.app.getTranslatedText(result.text);
    } catch (err) {
      return value;
    }
  }

  getOptionsAndTextsForASpecificField(id: string) {
    const optionsTexts: any[] = [];

    let optionText: {
      text: string;
      value: string;
    };

    const options = this.app.field.getOptions(id);
    options.forEach((option) => {
      optionText = {
        text: this.app.field.getOptionText(id, option),
        value: option,
      };
      optionsTexts.push(optionText);
    });
    return optionsTexts;
  }

  getOptionColor(id: string, value: string) {
    try {
      const { options } = this.getFieldSettings(id);
      if (options == null) {
        throw new Error("no options");
      }
      const color = options[value].color;
      if (color == null) {
        return "";
      }
      return color;
    } catch (err) {
      return "";
    }
  }

  isOptionSelected(fieldId: string, option: string, model: DocModel) {
    if (model == null) {
      return false;
    }
    if (this.isMultiple(fieldId)) {
      const selectedOptions = new Set(model[fieldId] as string[]);
      return selectedOptions.has(option);
    } else {
      const selectedOption = model[fieldId] as string;
      /** special case for the roles, since it is an option type of field, but allows only one value to be saved
       * into the db, but it has to be stored as []
       */
      if (fieldId === "user.roles") {
        const currentOption = this.app.customers.expectCurrent + "-" + option;
        const selectedOptions = new Set(model[fieldId] as string[]);
        return selectedOptions.has(currentOption);
        /** option type of field but with single value to be saved, as string  */
      } else {
        return option === selectedOption;
      }
    }
  }

  getAutocompleteFunction(
    field: string,
    option: string
  ): (model: DocModel) => void {
    let result: ((model: DocModel) => void) | undefined;

    if (field === this.app.fieldId.thread.dinCode) {
      return () => this.app.thread.setDinCode(option);
    }
    if (field === this.app.fieldId.thread.dinText) {
      return () => this.app.thread.setDinCode(option);
    }
    if (field === this.app.fieldId.pcnGenerator.dinCode) {
      return () => this.app.pcnGenerator.setDinCode(option);
    }
    if (field === this.app.fieldId.pcnGenerator.dinText) {
      return () => this.app.pcnGenerator.setDinCode(option);
    }
    if (
      field === this.app.fieldId.thread.commodityClass &&
      this.app.customers.expectCurrent === Customer.MRCE
    ) {
      return () => this.app.thread.setUsedIn(option);
    }
    if (field === this.app.fieldId.thread.omfCommodityId) {
      return () => this.app.thread.setCommodityResponsible(option);
    }
    if (
      (field === this.app.fieldId.thread.indicatorName ||
        field === this.app.fieldId.thread.dinCodeRespName) &&
      this.app.customers.expectCurrent === Customer.RHEINBAHN
    ) {
      return () => this.app.person.setPersonFields(field, option);
    }

    // if (
    //   field === this.app.fieldId.thread.dinCodeRespName &&
    //   this.app.customer === "db"
    // ) {
    //   result = this.app.users.getOptions(field, "thread", "team").get(option);
    // }
    if (result == null) {
      result = () => {};
    }
    return result;
  }

  getFieldSettings(id: string) {
    const [type, name] = id.split(".");
    const componentSettings = this.app.settings[
      type as Type
    ] as _ComponentSettings;
    return componentSettings.field[name];
  }

  toggleOption(field: string, option: string, model: DocModel) {
    if (this.isMultiple(field)) {
      const selectedOptions = new Set(model[field] as string[]);
      if (selectedOptions.has(option)) {
        // deselect option
        selectedOptions.delete(option);
      } else {
        selectedOptions.add(option);
      }
      model[field] = Array.from(selectedOptions).sort();

      // in case no option remains selected
      if (model[field].length === 0) {
        model[field] = [];
        if (field === "thread.applicationArea") {
          this.app.thread.externalChanges = false;
        }
      }

      return;
    }

    if (model[field] === option) {
      // deselect option
      model[field] = "";
      this.getAutocompleteFunction(field, "")(model);

      // special case when the artNumber is selected or deselected -> show/not show the impacts section
      if (field === "thread.artNumber") {
        this.app.state.partsToVehicles = [];
        sessionStorage.removeItem("fromTree");
      }

      return;
    }
    /** if the customer is db and the value from the prirority field is set => set a default Due Date based on the prirority */
    if (
      field === "thread.priority" &&
      this.app.customers.expectCurrent === Customer.DB
    ) {
      model["thread.dueDate"] = this.app.thread.setDefaultDueDate(option);
    }
    if (
      field === "thread.artNumber" &&
      this.app.customers.expectCurrent === Customer.NS
    ) {
      if (this.app.thread.cleanModel[field] !== option) {
        this.displayCassesPerCpn = true;
      }
    }

    if (field === "user.roles" && this.app.filterList.field !== "user.roles") {
      model[field] = [this.app.customers.expectCurrent + "-" + option];
    } else {
      model[field] = option;
    }
    /** set substanceIdentification and casNumber fields based on each other's selection */
    if (field === "SVHCItems.substanceIdentification") {
      model = this.app.SVHCItems.setOptions(option, model, field);
    }
    if (field === "SVHCItems.casNumber") {
      model = this.app.SVHCItems.setOptions(option, model, field);
    }
    this.getAutocompleteFunction(field, option)(model);
  }

  getFieldListBasedOnType(type?: string) {
    let docType = this.app.import.type;
    if (docType == null) {
      return;
    }
    if (type) {
      docType = type;
    }
    const props: any[] = [];
    let fieldList: string[] = [];
    if (this.app.import.type === "assembly") {
      fieldList = this.app.field.getAll("part");
    } else {
      fieldList = this.app.field.getAll(docType);
    }
    const language = localStorage.getItem("LANGUAGE");
    fieldList.forEach((field) => {
      const key = field.split(".")[1];
      if (language !== null) {
        props.push({
          key,
          label: (this.app.field.getFieldSettings(field).text as any)[language],
          type: this.app.field.getFieldSettings(field).type,
        });
      }
    });
    if (props === undefined) {
      return;
    }
    return props as any;
  }

  isValid(field: string, value: any) {
    const required = this.isRequired(field);
    const isValue = this.isValue(value);
    if (required && !isValue) {
      return false;
    }
    if (this.isUnique(field)) {
      return false;
    }
    if (required && !this.isValidRegex(field, value)) {
      return false;
    }
    return true;
  }

  getInvalidFields(type: Type, model = this.app.model) {
    return this.getAll(type).filter((id) => !this.isValid(id, model[id]));
  }

  resetAllRequiredFieldSettings(type: Type): void {
    const fields = this.getAll(type);

    fields.forEach((field) => {
      this.getFieldSettings(field).required = false;
    });
  }

  getColor(
    fieldId: string,
    status:
      | ObsolescenceStatus
      | ObsolescencelifeCycleRisk
      | EuRoHSStatus
      | MineralStatus
      | ReachAffectedStatus
  ) {
    // let color = this.getOptionColor(fieldId, status);
    if (
      status === "unconfirmed" ||
      status === "unknown" ||
      status === "undeterminable" ||
      status === "not required"
    ) {
      return {
        backgroundColor: this.getOptionColor(fieldId, status),
        color: "#263238",
        borderColor: "#263238",
      };
      // } else if (status === "unknown") {
      //   return {
      //     backgroundColor: this.getOptionColor(fieldId, status),
      //     borderColor: "#909090",
      //     color: "#909090",
      //   };
    } else {
      return { backgroundColor: this.getOptionColor(fieldId, status) };
    }
  }

  getDisplayValue(field: string, value: any) {
    if (field === null) {
      return "" + value;
    }
    return this.app.field.getFieldValueAsText(field, value);
  }

  // used to determine the needed keys to be able to save them correctly to the db
  handleSpecialLogic(field: string, value: string) {
    if (field === undefined || value === undefined) {
      return;
    }
    const currentField = this.app.import.type + "." + field;
    const options =
      this.app.field.getOptionsAndTextsForASpecificField(currentField);
    if (options === undefined) {
      return;
    }

    let result: string[] = [];
    result = value.split(",");
    const cleanResult: string[] = [];
    result.forEach((v) => {
      v = v.trim();
      const option = options.find((o) => o.text === v).value;
      cleanResult.push(option);
    });
    return result;
  }

  getItemsAsString(items: any[]) {
    let stringItems = "";

    /** TODO replace for with forEach */
    if (items && items.length) {
      for (let i = 0; i < items.length; i++) {
        if (items[i] != null) {
          try {
            stringItems += " " + items[i];
            if (i < items.length - 1) {
              stringItems += ",";
            }
          } catch (err) {
            stringItems = "error";
          }
        }
      }
    }
    return stringItems;
  }

  getFieldValueAsArray(fieldId: string, value: string) {
    const text = this.getFieldValueAsText(fieldId, value);
    const split = text.split(",");
    return split;
  }

  /** check if the field has to be displayed as a url  */
  isHyperLink(fieldId: string) {
    if (
      this.app.unlockedId == "" ||
      this.app.unlockedId == null ||
      !this.app.post.isReadMode
    ) {
      switch (fieldId) {
        case this.app.fieldId.thread.artNumber:
          return this.app.thread.cpnExist;
        case this.app.fieldId.thread.crtNumber:
          return this.app.thread.mpnExist;
        case this.app.fieldId.thread.pcnLink:
          return true;
        default:
          return false;
      }
    }
    return false;
  }

  /** Indentify links in a text  */
  highlightLinks(text: string) {
    const regex =
      /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/g;

    if (text && typeof text === "string") {
      const highlightedText = text.replace(regex, (word) =>
        this.transformToLink(word)
      );
      return highlightedText;
    }
    return text;
  }

  /** Returns provided text with links wrapped in <a></a> tag, use only for InnerHtml! */
  transformToLink(link: string) {
    let hrefLink = link;
    if (link.startsWith("www")) {
      hrefLink = "//" + link;
    }

    // tslint:disable-next-line
    return '<a target="_blank" href="' + hrefLink + '">' + link + "</a>";
  }
  isLink(value: string) {
    const regex =
      /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/g;

    if (regex.test(value)) {
      return true;
    }
    return false;
  }

  async deleteDocAttachedToField() {
    if (this.current == null) {
      return;
    }

    const customer = this.app.customers.expectCurrent;
    const fieldId = this.current;
    const docType = fieldId.split(".")[0];
    const docId = this.app.docs.getIdByDocType(docType);
    const fileName = this.app.attachment.getDocNameByFieldId(fieldId);

    if (docType === "manufacturer") {
      // Check if current field still holds a link from SE, if yes, only delete that link
      const isLink = this.isLink(
        this.app.manufacturer.currentManufacturer[fieldId]
      );

      if (isLink) {
        this.app.manufacturer.currentManufacturer[fieldId] = "";
        await this.app.manufacturer.save();
        return;
      }
    }

    // Delete the attachment from the current field
    const fieldName = fieldId.split(".")[1];
    const attachmentLink = [
      customer,
      docType,
      EncodingUtils.encodeBase64(docId),
      fileName,
      fieldName,
    ].join("/");
    await deleteFile(attachmentLink);
    await this.app.docs.getModelByDocType(docType, docId);
  }

  getDateColor(field: string, model: any) {
    if (field === "post.taskDueDate") {
      return this.app.tasks.getTaskDueDateColor(
        model[this.app.fieldId.post.taskDueDate],
        model[this.app.fieldId.post.taskCompleted]
      );
    }
  }

  setTwoDecimalsOnNumberFields(doc: Thread | Post | Part | Manufacturer) {
    const docKeys = Object.keys(doc);

    docKeys.forEach((key) => {
      if (
        this.app.field.getFieldSettings(key) != undefined &&
        this.app.field.getFieldSettings(key).type === "number"
      ) {
        doc[key] = this.app.field.formatNumberWithDecimals(doc[key], 2);
      }
    });
    return doc;
  }

  formatNumberWithDecimals(value: any, decimals: number) {
    // Check if number has comma in it an replace it with dot
    if (value != null && isNaN(value)) {
      value = value.replace(/\,/g, ".");
    }

    if (value != null && value !== "" && !isNaN(value)) {
      const num: number = Number(value);
      const numberHasDecimals = num % 1 === 0 ? true : false;

      if (!numberHasDecimals && decimals === 2) {
        return Math.round(num * 1e2) / 1e2;
      } else {
        return num;
      }
    }
  }

  getTableLabel(fieldId: string) {
    switch (fieldId) {
      case this.app.fieldId.manufacturer.partNumber:
      case this.app.fieldId.part.partNumber:
      case this.app.fieldId.alert.partNumber:
      case this.app.fieldId.pcn.artNumber:
      case this.app.fieldId.thread.artNumber:
        return this.app.text.RM.cpn;
      case this.app.fieldId.manufacturer.manufacturerPartNumber:
      case this.app.fieldId.alert.manufacturerPartNumber:
      case this.app.fieldId.pcn.crtNumber:
      case this.app.fieldId.thread.crtNumber:
        return this.app.text.RM.mpn;
      case this.app.fieldId.manufacturer.estimatedYearsToEOL:
        return this.app.text.RM.yteop;
      case this.app.fieldId.manufacturer.estimatedEOLDate:
        return this.app.text.RM.eop;
      case this.app.fieldId.manufacturer.ltbDate:
      case this.app.fieldId.alert.severity:
        return this.app.text.RM.eos;
      case this.app.fieldId.manufacturer.active:
        return this.app.text.manufacturer.activeShort;
      case this.app.fieldId.part.obsolescenceManagement:
        return this.app.text.part.obsolescenceManagementShort;
      case this.app.fieldId.pcn.oDate:
        return this.app.text.pcn.oDate;
      case this.app.fieldId.pcn.creator:
        return this.app.text.pcn.creator;
      case this.app.fieldId.pcn.pcnID:
        return this.app.text.pcn.pcnID;
      case this.app.fieldId.pcn.changeClasses:
        return this.app.text.pcn.changeClasses;
      case this.app.fieldId.pcn.itemEOP:
        return this.app.text.pcn.effectiveDate;
      case this.app.fieldId.pcn.omfShortDescr:
      case this.app.fieldId.change.omfShortDescr:
        return this.app.text.pcn.omfShortDescr;
      case this.app.fieldId.part.quantity:
        return this.app.text.part.qty;
      case this.app.fieldId.part.sapStatus:
      case this.app.fieldId.manufacturer.sapStatus:
        return this.app.text.part.sap;
      case this.app.fieldId.manufacturer.supplierPending:
        return this.app.text.manufacturer.pending;
      case this.app.fieldId.manufacturer.maxWeeks:
        return this.app.text.manufacturer.maxWeeks;
      case this.app.fieldId.manufacturer.countryOfOrigin:
        return this.app.text.manufacturer.origin;
      case this.app.fieldId.manufacturer.averagePrice:
        return this.app.text.manufacturer.averagePrice;
      case this.app.fieldId.manufacturer.totalMarketInv:
        return this.app.text.manufacturer.totalMarketInv;
      case this.app.fieldId.manufacturer.numberOfDistributors:
        return this.app.text.manufacturer.numberOfDistributors;
      default:
        return this.app.field.getLabel(fieldId);
    }
  }

  changeFieldSettings(
    fieldId: string,
    fieldType: FieldType,
    fieldRequired?: boolean
  ) {
    switch (fieldId) {
      case this.app.fieldId.thread.pcnID:
      case this.app.fieldId.thread.masterRecord:
      case this.app.fieldId.manufacturer.partNumber:
      case this.app.fieldId.manufacturer.manufacturerPartNumber:
      case this.app.fieldId.manufacturer.name:
      case this.app.fieldId.manufacturer.purchasePartNumber:
      case this.app.fieldId.manufacturer.designType:
      case this.app.fieldId.manufacturer.sapStatus:
      case this.app.fieldId.manufacturer.stockRange:
      case this.app.fieldId.manufacturer.euRoHSVersion:
      case this.app.fieldId.manufacturer.exemption:
      case this.app.fieldId.manufacturer.expirationDate:
      case this.app.fieldId.manufacturer.partner:
      case this.app.fieldId.manufacturer.estimatedEOLDate:
      case this.app.fieldId.manufacturer.estimatedYearsToEOL:
      case this.app.fieldId.manufacturer.productEOSR:
      case this.app.fieldId.manufacturer.productYearsToEOSR:
      case this.app.fieldId.manufacturer.reachSubstances:
      case this.app.fieldId.manufacturer.svhcListVersion:
      case this.app.fieldId.manufacturer.maxWeeks:
      case this.app.fieldId.manufacturer.minWeeks:
      case this.app.fieldId.manufacturer.averagePrice:
      case this.app.fieldId.manufacturer.lastUpdatedOn:
      case this.app.fieldId.manufacturer.numberOfDistributors:
      case this.app.fieldId.part.partNumber:
      case this.app.fieldId.part.designType:
      case this.app.fieldId.part.sapStatus:
      case this.app.fieldId.part.stockRange:
      case this.app.fieldId.part.globalStock:
      case this.app.fieldId.part.description:
      case this.app.fieldId.part.actualStock:
      case this.app.fieldId.part.stockPAS:
      case this.app.fieldId.part.stockMS5:
      case this.app.fieldId.part.stockPS2:
      case this.app.fieldId.part.electronicalMat:
      case this.app.fieldId.part.productHierarchy:
      case this.app.fieldId.thread.artNumber:
      case this.app.fieldId.thread.crtNumber:
      case this.app.fieldId.thread.omfNumber:
      case this.app.fieldId.thread.creator:
        const currentFieldType = this.app.field.getFieldSettings(fieldId).type;
        const fieldRequiredStatus =
          this.app.field.getFieldSettings(fieldId).required;

        // in this object we store all default information for the field
        const fieldSettings: any = {};
        fieldSettings[fieldId] = {};
        fieldSettings[fieldId]["type"] = currentFieldType;

        // if no parameter has been sent for the required property it should not be added on the object
        if (fieldRequired != undefined) {
          this.app.field.getFieldSettings(fieldId).required = fieldRequired;
        } else {
          fieldSettings[fieldId]["required"] = fieldRequiredStatus;
        }
        this.defaultSettings.push(fieldSettings);
        this.app.field.getFieldSettings(fieldId).type = fieldType;
        return;
    }
  }

  resetFieldType(fieldId: string) {
    switch (fieldId) {
      case this.app.fieldId.thread.artNumber:
      case this.app.fieldId.thread.crtNumber:
      case this.app.fieldId.thread.omfNumber:
      case this.app.fieldId.thread.creator:
      case this.app.fieldId.thread.pcnID:
      case this.app.fieldId.thread.masterRecord:
      case this.app.fieldId.manufacturer.partNumber:
      case this.app.fieldId.manufacturer.manufacturerPartNumber:
      case this.app.fieldId.manufacturer.name:
      case this.app.fieldId.manufacturer.purchasePartNumber:
      case this.app.fieldId.manufacturer.designType:
      case this.app.fieldId.manufacturer.sapStatus:
      case this.app.fieldId.manufacturer.stockRange:
      case this.app.fieldId.manufacturer.euRoHSVersion:
      case this.app.fieldId.manufacturer.exemption:
      case this.app.fieldId.manufacturer.expirationDate:
      case this.app.fieldId.manufacturer.partner:
      case this.app.fieldId.manufacturer.estimatedEOLDate:
      case this.app.fieldId.manufacturer.estimatedYearsToEOL:
      case this.app.fieldId.manufacturer.productEOSR:
      case this.app.fieldId.manufacturer.productYearsToEOSR:
      case this.app.fieldId.manufacturer.reachSubstances:
      case this.app.fieldId.manufacturer.svhcListVersion:
      case this.app.fieldId.manufacturer.maxWeeks:
      case this.app.fieldId.manufacturer.minWeeks:
      case this.app.fieldId.manufacturer.averagePrice:
      case this.app.fieldId.manufacturer.lastUpdatedOn:
      case this.app.fieldId.manufacturer.numberOfDistributors:
      case this.app.fieldId.part.partNumber:
      case this.app.fieldId.part.designType:
      case this.app.fieldId.part.sapStatus:
      case this.app.fieldId.part.stockRange:
      case this.app.fieldId.part.globalStock:
      case this.app.fieldId.part.description:
      case this.app.fieldId.part.actualStock:
      case this.app.fieldId.part.stockPAS:
      case this.app.fieldId.part.stockMS5:
      case this.app.fieldId.part.stockPS2:
      case this.app.fieldId.part.electronicalMat:
      case this.app.fieldId.part.productHierarchy:
        this.defaultSettings.find((field: any) => {
          const isConteined = field.hasOwnProperty(fieldId);
          if (isConteined) {
            this.app.field.getFieldSettings(fieldId).type = field[fieldId].type;
            this.app.field.getFieldSettings(fieldId).required =
              field[fieldId].required;
          }
        });
        return;
    }

    this.defaultSettings = [];
  }

  async getManufacturerCodeByName(value: string) {
    const manufacturerDoc = await getManufacturerCodeByName(value);
    if (!manufacturerDoc || manufacturerDoc["cageCode"].length === 0) {
      return;
    }
    this.app.thread.thread[this.app.fieldId.thread.cageCode] =
      manufacturerDoc["cageCode"][0];
    this.manufacturerCodes = manufacturerDoc["cageCode"];
  }

  setManufacturerCode(code: string) {
    this.app.thread.thread[this.app.fieldId.thread.cageCode] = code;
  }

  async getManufacturerNameByCode(value: string) {
    const manufacturerDoc: any = await getManufacturerNameByCode(value);
    this.app.thread.thread[this.app.fieldId.thread.creator] =
      manufacturerDoc[0].manufacturerName;
  }

  async getTypeaheadOptionsForCageCode() {
    const { cageCodes } = await getCageCodeList();
    this.cageCodeList = cageCodes;
  }
}

interface OptionList {
  value: string;
  text: string;
}
