<app-modal
  #deleteAlertsModal
  [modalMessage]="app.text.alert.deleteMessage"
  [modalTitle]="app.text.alert.modalTitle"
  (ok)="app.alerts.deleteAlerts()"
>
</app-modal>
<app-paginator
  [length]="app.alerts.allAlerts.length"
  [index]="app.paginator.focus"
  (focus)="app.paginator.focus = $event"
></app-paginator>
<div class="row" *ngIf="app.table.selected.size > 0">
  <div class="col-md-4 ps-1">
    <div class="d-inline-flex selectedAlerts">
      <a
        class="me-3 pointer"
        *ngIf="app.table.selected.size > 0"
        (click)="app.alerts.markAsSeeen()"
      >
        <i class="fas fa-check-double fa-green"></i> &nbsp;
        {{ app.text.alert.markAsSeen }}
      </a>
      <a
        class="pointer"
        *ngIf="app.table.selected.size > 0"
        (click)="app.alerts.deleteAlerts()"
      >
        <i class="fas fa-trash"></i> &nbsp;
        {{ app.text.alert.deleteAllSelected }}
      </a>
    </div>
  </div>
  <div class="col-md-4 text-center">
    <div class="text-center d-inline-flex selectedAlerts">
      <span> {{ app.table.selected.size }} {{ app.text.mail.selected }} </span>
      <a
        class="selectAllButtton"
        (click)="app.table.selectAll(app.alerts.allAlerts, true)"
        *ngIf="app.table.selected.size !== app.alerts.allAlerts.length"
      >
        {{ app.text.mail.selectAll }} {{ app.alerts.allAlerts.length }}
      </a>
      <a
        class="selectAllButtton"
        *ngIf="app.table.selected.size === app.alerts.allAlerts.length"
        (click)="app.table.clearSelection()"
      >
        {{ app.text.mail.deselectAll }}
      </a>
    </div>
  </div>
</div>

<div
  class="tableFixHead"
  [ngClass]="{ tableHeight: app.filterTable.isDropdownOpen }"
>
  <table class="table table-striped spacing-table">
    <thead>
      <tr>
        <th class="p-1" *ngFor="let column of app.alerts.columns">
          <div class="d-inline-flex">
            <div
              [ngClass]="{
                'resize-table': app.table.canColumnBeResized(column)
              }"
            >
              <span
                (click)="
                  app.table.toggleSortDirection(column);
                  app.table.sort(app.alerts.allAlerts)
                "
                *ngIf="column !== 'part.partsToVehicles'"
                >{{ app.field.handleSpecialLogicLabels(column) }}
              </span>
              <div *ngIf="column === 'part.partsToVehicles'">
                <i class="fas fa-sitemap fa-rotate-270 pointer"></i>
              </div>
            </div>
            &nbsp;
            <span *ngIf="column === app.table.sortField">
              <i
                *ngIf="app.table.sortDirection === 1"
                class="fa fa-chevron-up"
              ></i>
              <i
                *ngIf="app.table.sortDirection === -1"
                class="fa fa-chevron-down"
              ></i>
            </span>
          </div>
          <div *ngIf="column === 'select'">
            <button
              class="btn btn-hidden pt-0 ps-0"
              (click)="app.table.clearSelection()"
              *ngIf="app.table.selected.size !== 0"
            >
              <i class="far fa-check-square"></i>
            </button>
            <button
              class="btn btn-hidden pt-0 ps-0"
              *ngIf="app.table.selected.size === 0"
              (click)="app.table.selectAll(app.alerts.allAlerts)"
            >
              <i class="far fa-square"></i>
            </button>
          </div>
          <div
            *ngIf="
              app.filterTable.getFilterTableColumns(
                app.mail.getColumnShort(column)
              )
            "
          >
            <app-filter-table
              [column]="app.mail.getColumnShort(column)"
              [columns]="app.alerts.columns"
              [docType]="'alert'"
              [cleanDocs]="app.alerts.cleanAlerts"
              [selectedFilters]="selectedFilters"
              (resultedDocs)="app.filterTable.getFilterResults($event, 'alert')"
            >
            </app-filter-table>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let alert of app.paginator.getPage(app.alerts.allAlerts)"
        [ngClass]="{
          isSelected: app.isSelected(alert)
        }"
      >
        <td class="p-1" *ngFor="let column of app.alerts.columnsShort">
          <div [ngSwitch]="app.table.getColumnTypeDisplay(column)">
            <div *ngSwitchCase="'date'">
              {{ alert[column] | date : "yyyy-MM-dd" }}
            </div>
            <div *ngSwitchCase="'navigateAction'">
              <a class="partNumber" (click)="navigateTo(column, alert)">
                {{ alert[column] }}
              </a>
            </div>
            <div *ngSwitchCase="'actions'">
              <div *ngIf="column === 'createCase'">
                <i
                  class="far fa-folder-plus click-area icon-blue pointer"
                  *ngIf="
                    !app.thread.getCase(alert) &&
                    app.permission.alert.canCreateCase
                  "
                  (click)="
                    app.thread.newCase(alert);
                    app.alerts.currentSelected = alert
                  "
                ></i>
                <i
                  class="fas fa-folder-open click-area icon-blue pointer"
                  *ngIf="app.thread.getCase(alert)"
                  (click)="
                    seeOpenedCasesModal.open();
                    app.alerts.currentSelected = alert;
                    app.thread.getCasesByPartNumber(alert)
                  "
                ></i>
              </div>
              <div *ngIf="column === 'checkedByOm'">
                <button
                  class="btn btn-hidden pt-0 ps-0"
                  *ngIf="alert.update_user !== undefined && alert[column]"
                  [ngbTooltip]="
                    alert.update_user +
                    ' ' +
                    app.text.credits.on +
                    ' ' +
                    (alert.update_time | date : 'yyyy-MM-dd')
                  "
                >
                  <i class="fas fa-check-double fa-green"></i>
                </button>
                <button
                  class="btn btn-hidden pt-0 ps-0"
                  *ngIf="alert.update_user == undefined && alert[column]"
                >
                  <i class="fas fa-check-double fa-green"></i>
                </button>
                <button class="btn btn-hidden pt-0 ps-0" *ngIf="!alert[column]">
                  <i class="fas fa-exclamation-circle fa-orange"></i>
                </button>
              </div>
              <div *ngIf="column === 'select'">
                <button
                  class="btn btn-hidden pt-0 ps-0"
                  *ngIf="!alert[column]"
                  (click)="app.table.toggleSelect(alert)"
                >
                  <i
                    class="far fa-check-square"
                    *ngIf="app.table.selected.has(alert)"
                  ></i>
                  <i
                    class="far fa-square"
                    *ngIf="!app.table.selected.has(alert)"
                  ></i>
                </button>
              </div>
            </div>
            <div
              class="pointer"
              *ngSwitchCase="'normal'"
              (click)="navigateTo(column, alert)"
            >
              {{ alert[column] }}
            </div>
            <div *ngSwitchCase="'link'">
              <div class="text-center">
                <a *ngIf="alert[column]" [href]="alert[column]" target="_blank">
                  <i class="fas fa-file icon-blue"></i>
                </a>
              </div>
            </div>
            <div
              *ngSwitchCase="'partsToVehicles'"
              (click)="
                seeStructureModal.open();
                app.treeRow.generateImpactsFromParts(alert['partNumber'])
              "
              [title]="app.field.getLabel(app.fieldId.part.partsToVehicles)"
            >
              <i class="fas fa-sitemap fa-rotate-270 icon-blue pointer"></i>
            </div>
            <div *ngSwitchCase="'getFieldValueAsText'">
              <div *ngIf="column === 'alertCategory'">
                {{
                  app.field.getFieldValueAsText(
                    "alert." + column,
                    alert[column]
                  )
                }}

                <span
                  *ngIf="alert['alertCategory'] === 'range_not_before_date'"
                >
                  {{ alert["monitoringRangeUpTo"] }}
                </span>
              </div>

              <div *ngIf="column !== 'alertCategory'">
                {{
                  app.field.getFieldValueAsText(
                    "alert." + column,
                    alert[column]
                  )
                }}
              </div>
            </div>
            <div *ngSwitchCase="'optionText'">
              <span
                class="badge badgeStatus"
                [ngStyle]="
                  app.field.getColor(
                    app.fieldId.alert.criticality,
                    alert.criticality
                  )
                "
              >
                {{ app.field.getOptionText("alert." + column, alert[column]) }}
              </span>
            </div>
            <div *ngSwitchCase="'badge'">
              <span
                class="col-6 btn badge"
                [ngStyle]="app.field.getColor('alert.' + column, alert[column])"
                [ngClass]="{ darkText: column === 'severity' }"
                >{{
                  app.field.getOptionText("alert." + column, alert[column])
                }}</span
              >
            </div>
            <div class="fixedWidth" *ngSwitchCase="'limit'">
              <span (click)="$event.stopPropagation()">
                <app-limit [textInput]="alert[column]" limit="20"> </app-limit>
              </span>
            </div>
            <div *ngSwitchCase="'decimalNumber'">
              <span *ngIf="alert[column] > -1">
                {{ app.field.formatNumberWithDecimals(alert[column], 2) }}
              </span>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td *ngIf="loading" colspan="99" class="text-center">
          {{ this.app.text.core.loading }}
        </td>
        <td
          *ngIf="!loading && app.alerts.allAlerts.length === 0"
          colspan="99"
          class="text-center"
        >
          {{ app.text.app.noResults }}
        </td>
      </tr>
    </tbody>
  </table>
</div>

<app-paginator
  [length]="app.alerts.allAlerts.length"
  [index]="app.paginator.focus"
  (focus)="app.paginator.focus = $event"
></app-paginator>
<app-modal
  #seeStructureModal
  [modalTitle]="app.text.part.whereUsed"
  [showButtons]="false"
  [modalType]="'showWhereUsed'"
>
</app-modal>

<app-modal
  #seeOpenedCasesModal
  [modalTitle]="app.text.thread.threads"
  [modalType]="'showListOfCases'"
  [showButtons]="false"
>
</app-modal>
