<app-manufacturer-matching-modal
  #unMatchModal
  [match]="manufacturer"
  [type]="'discard'"
>
</app-manufacturer-matching-modal>

<app-manufacturer-matching-modal
  #saveMatchModal
  [match]="currentMatch"
  [manufacturer]="manufacturer"
  [type]="'save'"
>
</app-manufacturer-matching-modal>

<div class="col-12 no-padding">
  <div class="sub-title">
    <div class="control-bar">
      <button
        class="btn btn-control p-2"
        (click)="app.routing.closeTab()"
        [title]="app.text.thread.close"
      >
        <i class="far fa-arrow-left"></i> &nbsp; {{ app.text.app.back }}
      </button>
    </div>
    <!-- <div class="p-2 px-3 heading">
      {{ app.text.matchingManufacturer.matchingItem }}
    </div> -->
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <!-- Original Data -->
    <div class="col-md-6 col-lg-6 col-xl-6">
      <div class="card my-3">
        <div class="card-header">
          <!-- <span class="font-weight-bold"> -->
          {{ app.text.matchingManufacturer.originalData }}
          <!-- </span> -->
        </div>
        <div class="card-body py-2">
          <div>
            <span class="origLabel">
              {{
                app.field.getLabel(app.fieldId.manufacturer.partNumber)
              }}:</span
            >
            <span class="fieldRow">
              <a
                class="links pointer"
                *ngIf="part && part.partNumber"
                (click)="app.part.navigateToPartDetails(part.partNumber)"
              >
                {{ part.partNumber }}
              </a>
            </span>
          </div>
          <div>
            <span class="origLabel">
              {{
                app.field.getLabel(
                  app.fieldId.manufacturer.manufacturerPartNumberRaw
                )
              }}:</span
            >
            <span class="fieldRow">
              <a
                class="links pointer"
                *ngIf="manufacturer._id"
                (click)="
                  app.manufacturer.navigateToManufacturerDetails(
                    manufacturer._id
                  )
                "
                >{{ manufacturer.manufacturerPartNumberRaw }}
              </a>
            </span>
          </div>
          <div>
            <span class="origLabel">
              {{app.field.getLabel(app.fieldId.manufacturer.name)}}:</span>
            <span class="fieldRow">
                 {{ manufacturer.name }}
            </span>
          </div>
          <div>
            <span class="origLabel">
              {{ app.field.getLabel(app.fieldId.manufacturer.nameRaw) }}:</span
            >
            <span class="fieldRow"> {{ manufacturer.nameRaw }}</span>
          </div>
          <div>
            <span class="origLabel">
              {{
                app.field.getLabel(
                  app.fieldId.manufacturer.manufacturerPartDescriptionRaw
                )
              }}:</span
            >
            <span class="fieldRow">
              {{ manufacturer.manufacturerPartDescriptionRaw }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <!-- Current Match -->
    <div class="col-md-6 col-lg-6 col-xl-6">
      <div class="card my-3">
        <div class="card-header py-2">
          <div class="d-flex justify-content-between">
            <span class="pt-1" [ngClass]="{ 'pb-2': !displayUnmatched() }">
              {{ app.text.matchingManufacturer.currentMatch }}
            </span>
            <div *ngIf="displayUnmatched()">
              <button
                class="btn btn-sm btn-default-red"
                (click)="unMatchModal.open()"
              >
                <i class="far fa-unlink"></i>&nbsp;&nbsp;{{
                  app.text.matchingManufacturer.unmatch
                }}
              </button>
            </div>
          </div>
        </div>

        <div class="card-body py-2">
          <div>
            <span class="origLabel text-center">
              {{ app.text.matchingManufacturer.matchingStatus }}</span
            >
            <span class="mx-2">
              <button class="btn badge-color">
                <div
                  *ngIf="
                    app.manufacturer.getMatchingStatusOfManufacturer(
                      manufacturer
                    ) === 'matched'
                  "
                >
                  <i class="far fa-link"></i>&nbsp;&nbsp;{{
                    app.text.RM.matched
                  }}
                </div>
                <div
                  *ngIf="
                    app.manufacturer.getMatchingStatusOfManufacturer(
                      manufacturer
                    ) === 'manualAssignment'
                  "
                >
                  <i class="fas fa-user-edit"></i>&nbsp;&nbsp;{{
                    app.text.matchingManufacturer.edited
                  }}
                </div>
                <div
                  *ngIf="
                    app.manufacturer.getMatchingStatusOfManufacturer(
                      manufacturer
                    ) === 'manual'
                  "
                >
                  <i class="fa fa-user-circle"></i>&nbsp;&nbsp;{{
                    app.text.RM.manual
                  }}
                </div>
                <div
                  *ngIf="
                    app.manufacturer.getMatchingStatusOfManufacturer(
                      manufacturer
                    ) === 'unmatched'
                  "
                >
                  <i class="far fa-unlink"></i>&nbsp;&nbsp;{{
                    app.text.RM.unmatched
                  }}
                </div>
                <div
                  *ngIf="
                    app.manufacturer.getMatchingStatusOfManufacturer(
                      manufacturer
                    ) === 'override'
                  "
                >
                  <i class="far fa-hand-paper"></i>&nbsp;&nbsp;{{
                    app.text.RM.override
                  }}
                </div>
              </button>
            </span>
          </div>
          <div>
            <span class="origLabel">
              {{
                app.field.getLabel(
                  app.fieldId.manufacturer.manufacturerPartNumber
                )
              }}:</span
            >
            <span class="fieldRow">
              {{ manufacturer.manufacturerPartNumber }}</span
            >
          </div>
          <div>
            <span class="origLabel">
              {{ app.field.getLabel(app.fieldId.manufacturer.name) }}:</span
            >
            <span class="fieldRow"> {{ manufacturer.name }}</span>
          </div>
          <div>
            <span class="origLabel">
              {{
                app.field.getLabel(
                  app.fieldId.manufacturer.manufacturerPartDescription
                )
              }}:</span
            >
            <span class="fieldRow">
              {{ manufacturer.manufacturerPartDescription }}</span
            >
          </div>
          <div>
            <span class="origLabel">
              {{ app.text.matchingManufacturer.datasheet }}</span
            >
            <span class="fieldRow text-center" *ngIf="manufacturer.datasheet">
              <a
                class="datasheet m-1"
                [href]="manufacturer.datasheet"
                target="_blank"
              >
                <i class="fas fa-file icon-blue"></i>
              </a>
            </span>
          </div>
          <div>
            <span class="origLabel">
              {{
                app.field.getLabel(app.fieldId.part.obsolescenceStatus)
              }}</span
            >
            <span
              class="mx-2 px-1 mb-3"
              *ngIf="manufacturer.obsolescenceStatus !== undefined"
            >
              <span
                class="badge badgeStatus"
                [ngStyle]="
                  app.field.getColor(
                    app.fieldId.manufacturer.obsolescenceStatus,
                    manufacturer.obsolescenceStatus
                  )
                "
              >
                {{
                  app.field.getOptionText(
                    app.fieldId.manufacturer.obsolescenceStatus,
                    manufacturer.obsolescenceStatus
                  )
                }}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bottom-line my-3"></div>

  <div class="me-auto p-2 heading">{{ app.text.thread.searchInComponent }}</div>

  <app-external-data-filter
  class="mb-4"
  [manufacturerId]="manufacturer._id"
  ></app-external-data-filter>
</div>
