import { ComponentSettings, FieldTypes, empty } from "../settings/settings";
import { creditsFields } from "./credits.settings";
import { checkBoxOptions } from "./manufacturer.settings";

const field = {
  omfNumber: {
    type: FieldTypes.value,
    text: {
      de: "Fall-Nummer",
      en: "Case Number",
      nl: "Issue Nummer",
    },
    search: false,
  },
  title_comment: {
    type: FieldTypes.value,
    text: {
      de: "Überschrift",
      en: "Title",
      nl: "Titel",
    },
    maxLength: 256,
  },
  disNumber: {
    type: FieldTypes.value,
    text: {
      de: "DIS - Nummer",
      en: "DIS - Number",
    },
  },
  cost: {
    type: FieldTypes.number,
    text: {
      de: "Kosten [€]",
      en: "Cost [€]",
      nl: "Prijs [€]",
    },
  },
  effort: {
    type: FieldTypes.value,
    text: {
      de: "Bisheriger Aufwand [h]",
      en: "Effort to date [h]",
    },
  },
  solutionImpacts: {
    type: FieldTypes.options,
    text: {
      de: "Betroffene Einheiten",
      en: "Impacts affected",
      nl: "Betrokken items",
    },
    options: {},
    multiple: true,
  },
  costForStorage: {
    type: FieldTypes.number,
    text: {
      de: "Jährliche Lagerkosten pro Artikel in Є",
      en: "Yearly cost for storage keeping per item in Є",
    },
  },
  pricePerItem: {
    type: FieldTypes.number,
    text: {
      de: "Preis je Einheit",
      en: "Price per item",
    },
  },
  yearsOfStorage: {
    type: FieldTypes.number,
    text: {
      de: "Jahre Lagerung",
      en: "Years of storage keeping",
    },
  },
  noOfItems: {
    type: FieldTypes.number,
    text: {
      de: "Anzahl der eingelagerten Items",
      en: "Number of items to be stored",
    },
  },
  totalAmount: {
    type: FieldTypes.number,
    text: {
      de: "Gesamtkosten in €",
      en: "Total amount in €",
    },
  },
  resolveClass: {
    type: FieldTypes.options,
    required: false,
    text: {
      de: "Lösung",
      en: "Solution",
      nl: "Afhandelstrategie",
    },
    options: {
      Alternative: {
        text: {
          de: "Substitut (Äquivalente / Alternative Einheit)",
          en: "Substitute (Equivalent / Alternative Item)",
          nl: "Substitute (Equivalent / Alternative Item)",
        },
      },
      "Alternativer Lieferant": {
        text: {
          de: "Veräußerung (Aftermarket / Alternativer Lieferant)",
          en: "Divestment (Aftermarket / Alternative Supplier)",
          nl: "Divestment (Aftermarket / Alternative Supplier)",
        },
      },
      "LTB-Last Time Buy": {
        text: {
          de: "Bevorratung (Life of Need Buy - LNB)",
          en: "Life of Need Buy - LNB",
          nl: "Life of Need Buy - LNB",
        },
      },
      Eigenfertigung: {
        text: {
          de: "Erweiterte Produktion (Extern / Intern)",
          en: "Extended Production (External / Internal)",
          nl: "Extended Production (External / Internal)",
        },
      },
      Instandsetzung: {
        text: {
          de: "Reparatur oder Service",
          en: "Repair or Service",
          nl: "Repair or Service",
        },
      },
      Kannibalisierung: {
        text: {
          de: "Rückgewinnung (Kannibalisierung)",
          en: "Reclamation (Cannibalisation)",
          nl: "Reclamation (Cannibalisation)",
        },
      },
      Redesign: {
        text: {
          de: "Design Änderung (Minor / Major)",
          en: "Design Change (Minor / Major)",
          nl: "Design Change (Minor / Major)",
        },
      },
      "Nichts tun": {
        text: {
          de: "Keine Auswirkung",
          en: "No Impact",
          nl: "Geen Impact",
        },
      },
      Preservation: {
        text: {
          de: "Konservierung",
          en: "Preservation",
          nl: "Preservation",
        },
      },
      "Existing Stock": {
        text: {
          de: "Vorhandener Bestand",
          en: "Existing Stock",
          nl: "Existing Stock",
        },
      },
    },
    sort: true,
  },
  resolveClassDescription: {
    type: FieldTypes.options,
    text: {
      de: "Beschreibung der Lösung",
      en: "Description of Solution",
      nl: "Toelichting bij voorgestelde afhandelstrategie",
    },
    options: {},
    search: false,
  },
  resolveCost: {
    type: FieldTypes.value,
    text: {
      de: "Kosten der Lösung in EUR",
      en: "Costs of the solution in EUR",
      nl: "Kosteninschatting van de afhandelstrategie in EUR",
    },
  },
  resolveCostType: {
    type: FieldTypes.value,
    text: {
      de: "Kostenart der Lösung",
      en: "Cost type of the solution",
      nl: "Kostensoort van de afhandelstrategie",
    },
  },
  acceptedSolution: {
    text: {
      de: "Final",
      en: "Final",
      nl: "Akkoord",
    },
    tooltip: {
      de: "Als Finale Lösung bestätigen",
      en: "Confirm as final Solution",
      nl: "Bevestig uw keuze Afhandelstrategie",
    },
    search: false,
    type: FieldTypes.checkbox,
    checkboxLabels: checkBoxOptions,
  },
  newContact: {
    type: FieldTypes.value,
    text: {
      de: "Neue Kontaktdaten",
      en: "New contact information",
      nl: "Nieuwe contactgegevens",
    },
  },
  newCrtNumber: {
    type: FieldTypes.value,
    text: {
      de: "Neue Herstellerteilenummer (HTN)",
      en: "New Manufacturer Part Number (MPN)",
      nl: "Nieuw Fabrikant Artikel Nummer (MPN)",
    },
  },
  newManufacturer: {
    type: FieldTypes.value,
    text: {
      de: "Neuer Hersteller",
      en: "New Manufacturer",
      nl: "Nieuwe Fabrikant",
    },
  },
  newSupplier: {
    type: FieldTypes.value,
    text: {
      de: "Neuer Lieferant",
      en: "New Supplier",
      nl: "Nieuwe Leverancier",
    },
  },
  newInternalNumber: {
    type: FieldTypes.value,
    text: {
      de: "Neue Interne Nummer",
      en: "New Internal Number",
      nl: "Nieuw Artikel Nummer (CPN)",
    },
  },
  solutionSource: {
    type: FieldTypes.options,
    text: {
      de: "Lösungsquelle",
      en: "Source of Solution",
    },
    options: {
      intern: {
        text: {
          de: "Intern",
          en: "Internal",
        },
      },
      portal: {
        text: {
          de: "Portal",
          en: "Portal",
        },
      },
      internet: {
        text: {
          de: "Internet",
          en: "Internet",
        },
      },
      other: {
        text: {
          de: "Andere",
          en: "Others",
        },
      },
    },
  },
  altCrtContact: {
    type: FieldTypes.value,
    text: {
      de: "Hersteller Ansprechpartner",
      en: "Manufacturer contact",
      nl: "Fabrikant Contactpersoon",
    },
  },
  altCrtNumber: {
    type: FieldTypes.value,
    text: {
      de: "Herstellerteilenummer (HTN)",
      en: "Manufacturer Part Number (MPN)",
      nl: "Fabrikant Artikel Nummer (MPN)",
    },
  },
  content: {
    type: FieldTypes.text,
    required: false, //set required to false only because it interfers with the required fields for a task, value is aready set to true when add/edit comm
    maxLength: 4096,
    text: {
      de: "Text",
      en: "Message",
      nl: "Tekst",
    },
  },
  snapshot: {
    type: FieldTypes.data,
    text: {
      de: "Snapshot",
      en: "Snapshot",
      nl: "Snapshot",
    },
    search: false,
  },
  ...creditsFields,
  responsibleRole: {
    type: FieldTypes.options,
    text: {
      de: "Rollen",
      en: "Role",
      nl: "Rollen",
    },
    options: {
      team: { text: { de: "OM Team", en: "OM Team", nl: "OM-team" } },
      componentResponsible: {
        text: { de: "Komponentenmanager", en: "Komponentenmanager" },
      },
      bavFv: {
        text: { de: "BAV-Fernverkehr", en: "BAV-Fernverkehr" },
      },
      bavRegio: { text: { de: "BAV-Regio", en: "BAV-Regio" } },
      bavCargo: { text: { de: "BAV-Cargo", en: "BAV-Cargo" } },
      bavNetz: { text: { de: "BAV InfraGo", en: "BAV InfraGo" } },
      mawiFv: {
        text: { de: "MaWi-Fernverkehr", en: "MaWi-Fernverkehr" },
      },
      mawiRegio: {
        text: { de: "MaWi Regio", en: "MaWi Regio" },
      },
      mawiCargo: {
        text: { de: "MaWi Cargo", en: "MaWi Cargo" },
      },
      mawiFzi: { text: { de: "MaWi Fzi", en: "MaWi Fzi" } },
      mawiNetz: { text: { de: "MaWi InfraGo", en: "MaWi InfraGo" } },
      helpDesk: { text: { de: "HelpDesk", en: "Help Desk", nl: "Helpdesk" } },
    },
    search: false,
  },
  taskResponsible: {
    type: FieldTypes.options,
    text: { de: "Verantwortlich", en: "Responsible", nl: "Taakeigenaar" },
    required: true,
    search: false,
  },
  taskDueDate: {
    type: FieldTypes.date,
    text: { de: "Fälligkeitsdatum", en: "Due Date", nl: "Vervaldatum" },
    required: true,
    search: false,
  },
  taskDescription: {
    type: FieldTypes.text,
    text: {
      de: "Aufgabenbeschreibung",
      en: "Task Description",
      nl: "Taakomschrijving",
    },
    required: true,
    maxLength: 4096,
    search: false,
  },
  taskCompleted: {
    type: FieldTypes.options,
    text: {
      de: "Aufgabe abgeschlossen",
      en: "Task Completed",
      nl: "Taak afgesloten",
    },
    options: {
      true: {
        text: {
          de: "Abgeschlossen",
          en: "Completed",
          nl: "Completed",
        },
      },
      false: {
        text: {
          de: "Offen",
          en: "Open",
          nl: "Open",
        },
      },
    },
  },
  taskNote: {
    type: FieldTypes.text,
    text: {
      de: "Hinweis zur Aufgabe",
      en: "Note to task",
      nl: "Nota aan de taak",
    },
    maxLength: 4096,
  },
  completedBy: {
    type: FieldTypes.value,
    text: {
      de: "Aufgabe abgeschlossen von",
      en: "Task Completed by",
      nl: "Taak voltooid door",
    },
    search: false,
  },
  completedAt: {
    type: FieldTypes.timestamp,
    text: {
      de: "at",
      en: "at",
      nl: "op",
    },
    search: false,
  },
  typeOfPost: {
    type: FieldTypes.text,
    text: { de: "Typ", en: "Type" },
    search: false,
  },
  stockingType: {
    type: FieldTypes.options,
    text: { de: "Bevorratungsart", en: "Stocking Type" },
    search: false,
    options: {
      finalStocking: { text: { de: "Endbevorratung", en: "Final Stocking" } },
      interimSolution: {
        text: { de: "Überbrückungslösung", en: "Interim solution" },
      },
    },
  },
  changeRequest: {
    type: FieldTypes.value,
    text: { de: "Änderungsantrag ECR", en: "Change request ECR" },
    search: false,
  },
  verification: {
    type: FieldTypes.value,
    text: { de: "Verifizierung ECV", en: "Verification ECV" },
    search: false,
  },
  supplyDocLink: {
    type: FieldTypes.value,
    text: {
      de: "Link zum Endbevorratungsdokument",
      en: "Link to Supply document",
    },
    search: false,
  },
  purchaseNumber: {
    type: FieldTypes.value,
    text: {
      de: "Bestellnummer",
      en: "Purchase number",
    },
    search: false,
  },
  quantity: {
    type: FieldTypes.number,
    text: {
      de: "Menge in Bestellung",
      en: "Quantity in order",
    },
    search: false,
  },
  newMaterialNumber: {
    type: FieldTypes.value,
    text: {
      de: "Neue Materialnummer (RRS)",
      en: "New material number (RRS)",
    },
    search: false,
  },
  orderedOn: {
    type: FieldTypes.date,
    text: { de: "Bestellt am", en: "Ordered on" },
    search: false,
  },
  leadTime: {
    type: FieldTypes.value,
    text: {
      de: "Durchlaufzeit (d)",
      en: "Time to solution (d)",
      nl: "Doorlooptijd (d)",
    },
    search: false,
  },
  deputy: {
    type: FieldTypes.value,
    text: {
      de: "Vertreter",
      en: "Deputy",
      nl: "Deputy",
    },
    mutable: false,
    search: false,
  },
  vehicleNames: {
    type: FieldTypes.text,
    text: {
      de: "Fahrzeugname",
      en: "Vehicle-Name",
      nl: "Materieelserie",
    },
    mutable: false,
    search: false,
  },
  hours: {
    type: FieldTypes.number,
    text: {
      de: "Stunden",
      en: "Hours",
      nl: "Hours",
    },
  },
  hourlyRate: {
    type: FieldTypes.number,
    text: {
      de: "Stundensatz (EUR)",
      en: "Hourly Rate (EUR)",
      nl: "Hourly Rate (EUR)",
    },
  },
  nonRecurrentCost: {
    type: FieldTypes.number,
    text: {
      de: "Non-Recurrent-Cost (EUR)",
      en: "Non-Recurrent-Cost (EUR)",
      nl: "Non-Recurrent-Cost (EUR)",
    },
  },
  designChangeCost: {
    type: FieldTypes.number,
    text: {
      de: "Gesamtkosten Designänderung [€]",
      en: "Total Design Change Cost [€]",
      nl: "Total Design Change Cost [€]",
    },
  },
  closedTasksCount: {
    type: FieldTypes.value,
    text: {
      de: "Anzahl erledigter Aufgaben",
      en: "Closed Tasks",
      nl: "Closed Tasks",
    },
  },
  openedTasksCount: {
    type: FieldTypes.value,
    text: {
      de: "Anzahl aller offener Aufgaben",
      en: "Opened Tasks",
      nl: "Opened Tasks",
    },
  },
  commentsCount: {
    type: FieldTypes.value,
    text: {
      de: "Anzahl Kommentare",
      en: "Comments",
      nl: "Comments",
    },
  },
  solutionsCount: {
    type: FieldTypes.value,
    text: {
      de: "Anzahl Lösungen",
      en: "Solutions",
      nl: "Solutions",
    },
  },
};

const settings = {
  permission: {
    edit: false,
    editOwn: false,
    add: false,
    seeRoles: false,
    showFinalSolution: false,
    canAddSolution: false,
    hasTasks: false,
    canAddTask: false,
    markTaskCompleted: false,
    markOwnTaskCompleted: false,
    reopenTask: false,
    addAttachment: true,
    canAddComment: true,
    seeAllTasksTab: false,
    canExportTasks: false,
    hasEditPermissionForTaskNote: false,
  },
  url: {},
  list: {
    featureFields: empty,
    tasksTableColumns: empty,
    tasksCsvExportColumns: empty,
    allTasksTableColumns: empty,
    createdTasksTableColumns: empty,
    taskFieldsList: empty,
    defaultSolutionFields: empty,
    substituteFieldsList: empty,
    redesignFieldsList: empty,
    divestmentFieldsList: empty,
    ltbSolutionFields: empty,
    //  can be used for adding extra fields on the solutions
    extraSolutionFields: empty,
    fieldsOnFilter: empty,
    //list of fields excluded from post component but displayed in post-item component
    excludedFieldsFromPost: empty,
    postsCsvExportColumns: empty,
  },
  text: {
    post: {
      de: "Kommentar",
      en: "Comment",
      nl: "Commentaar",
    },
    posts: {
      de: "Kommentare",
      en: "Comments",
      nl: "Commentaar",
    },
    solution: {
      de: "Lösung",
      en: "Solution",
      nl: "Afhandelstrategie",
    },
    solutions: {
      de: "Lösungen",
      en: "Solutions",
      nl: "Afhandelstrategieën",
    },
    postsList: {
      de: "Posts",
      en: "Posts",
      nl: "Posts",
    },
    task: {
      de: "Aufgabe",
      en: "Task",
      nl: "Taak",
    },
    tasks: {
      de: "Aufgaben",
      en: "Tasks",
      nl: "Taken",
    },
    myTasks: {
      de: "Meine Aufgaben",
      en: "My Tasks",
      nl: "Mijn Taken",
    },
    myOpenTasks: {
      de: "Meine Offenen Aufgaben",
      en: "My Open Tasks",
      nl: "Mijn Open Taken",
    },
    createdTasks: {
      de: "Erstellte Aufgaben",
      en: "Created Tasks",
      nl: "Gemaakte taken",
    },
    createdOpenTasks: {
      de: "Erstellte Offene Aufgaben",
      en: "Created Open Tasks",
      nl: "Gecreëerde Open Taken",
    },
    allOpenTasks: {
      de: "All Open Tasks",
      en: "All Open Tasks",
      nl: "All Open Tasks",
    },
    taskDecr: {
      de: "Aufgabenbeschreibung",
      en: "Task Description",
      nl: "Taakomschrijving",
    },
    taskDueDate: {
      de: "Fälligkeitsdatum",
      en: "Task Due Date",
      nl: "Vervaldatum",
    },
    creator: {
      de: "Erstellt von",
      en: "Created by",
      nl: "Gemaakt door",
    },
    markTaskCompleted: {
      de: "Markiere als Erledigt",
      en: "Mark as Completed",
      nl: "Markeren als afgerond",
    },
    markTaskIncomplete: {
      de: "Markiere als Unvollständig",
      en: "Mark as Uncompleted",
      nl: "Markeren als niet afgerond",
    },
    taskNoteInfo: {
      de: "Bitte geben Sie einen Hinweis zur Aufgabe bevor Sie sie schließen:",
      en: "Please give a note to the task before closing it:",
      nl: "Geef een notitie bij de taak voordat u hem afsluit:",
    },
    delete: {
      de: "Löschen",
      en: "Delete",
      nl: "Verwijderen",
    },
    edit: {
      de: "Bearbeiten",
      en: "Edit",
      nl: "Bewerk",
    },
    deleteCommentQuestion: {
      de: "Wollen Sie diesen Kommentar löschen?",
      en: "Would you like to delete this comment?",
      nl: "Wilt u deze opmerking verwijderen?",
    },
    deleteSolutionQuestion: {
      de: "Möchten Sie diese Lösung wirklich löschen?",
      en: "Would you like to remove this solution?",
      nl: "Wilt u deze afhandelsstrategie verwijderen?",
    },
    deleteTaskQuestion: {
      de: "Möchten Sie diese Aufgabe wirklich löschen?",
      en: "Would you like to remove this task?",
      nl: "Wilt u deze taak verwijderen?",
    },
    modalTitle: {
      de: "Bestätigung",
      en: "Confirm",
      nl: "Bewerken",
    },
    save: {
      de: "Speichern",
      en: "Save",
      nl: "Bewaar",
    },
    safetyEvaluationRequired: {
      de: "Für diese Art von Lösung ist eine Sicherheitsbewertung erforderlich.",
      en: "For this type of solution a safety evaluation is needed.",
      nl: "Voor deze afhandelstrategie is een VBD nodig.",
    },
    leaveSol: {
      de: "Hinterlassen Sie eine Lösung.",
      en: "Leave a solution below.",
      nl: "Laat een afhandelstrategie achter.",
    },
    leaveComm: {
      de: "Hinterlassen Sie einen Kommentar.",
      en: "Leave a comment below.",
      nl: "Laat een commentaar achter.",
    },
    leaveTask: {
      de: "Hinterlassen Sie eine Aufgabe.",
      en: "Leave a task below.",
      nl: "Laat en taak achter.",
    },
    addSol: {
      de: "Lösung hinzufügen",
      en: "Add solution",
      nl: "Afhandelstrategie toevoegen",
    },
    saveSol: {
      de: "Lösung speichern",
      en: "Save solution",
      nl: "Afhandelstrategie opslaan",
    },
    addCom: {
      de: "Kommentar hinzufügen",
      en: "Add comment",
      nl: "Commentaar toevoegen",
    },
    saveCom: {
      de: "Kommentar speichern",
      en: "Save comment",
      nl: "Commentaar opslaan",
    },
    addTask: {
      de: "Aufgabe hinzufügen",
      en: "Add task",
      nl: "Taak toevoegen",
    },
    saveTask: {
      de: "Aufgabe speichern",
      en: "Save task",
      nl: "Taak opslaan",
    },
    addPredefinedTask: {
      de: "Vordefinierte Aufgabe hinzufügen",
      en: "Add predefined Task",
      nl: "Add predefined Task",
    },
    markCompleted: {
      de: "Als erledigt markieren",
      en: "Mark as completed",
      nl: "Markeren als afgerond",
    },
    completed: { de: "Abgeschlossen", en: "Completed", nl: "Afgerond" },
    cancel: { de: "Abbrechen", en: "Cancel", nl: "Afbreken" },
    showMode: {
      de: "Anzeigen:",
      en: "Showing:",
      nl: "Tonen:",
    },
    openTasks: { de: "Offene Aufgaben", en: "Open Tasks", nl: "Open Taken" },
    closedTasks: {
      de: "Geschlossene Aufgaben",
      en: "Closed Tasks",
      nl: "Afgerond Taken",
    },
    byMe: {
      de: "Erstellt von mir",
      en: "Created by Me",
      nl: "Gemaakt door mij",
    },
    byOthers: {
      de: "Erstellt von anderen",
      en: "Created by Others",
      nl: "Gemaakt door anderen",
    },
    all: { de: "Alle", en: "All", nl: "Alle" },
    allTasks: { de: "Alle Aufgaben", en: "All Tasks", nl: "Alle Taken" },
    allSolutions: {
      de: "Alle Lösungen",
      en: "All Solutions",
      nl: "Alle Afhandelstrategieën",
    },
    allComments: {
      de: "Alle Kommentare",
      en: "All Comments",
      nl: "Alle Commentaren",
    },
    stream: { de: "Stream", en: "Stream", nl: "Stream" },
    sortToOldest: {
      de: "Sortieren neueste nach älteste",
      en: "Sort Newest to Oldest",
      nl: "Sorteer nieuwste op oudste",
    },
    sortToNewest: {
      de: "Sortieren älteste nach neueste",
      en: "Sort Oldest to Newest",
      nl: "Variety oudste op nieuwste",
    },
    sortToMyTasks: {
      de: "Meine Aufgaben zuerst",
      en: "My tasks on top",
      nl: "Meine Aufgaben ganz oben",
    },
    newest: { de: "Neueste", en: "Newest", nl: "Nieuwste" },
    oldest: { de: "Älteste", en: "Oldest", nl: "Oudste" },
    exportTasks: {
      de: "Liste exportieren",
      en: "Export list",
      nl: "Lijst exporteren",
    },
    export: {
      de: "Liste exportieren",
      en: "Export list",
      nl: "Lijst exporteren",
    },
    cpn: {
      de: "KTN",
      en: "CPN",
      nl: "CPN",
    },
    finalSolution: {
      de: "Endgültige Lösung",
      en: "Final Solution",
      nl: "Definitieve oplossing",
    },
    saveTasks: {
      de: "Save Tasks",
      en: "Save Tasks",
      nl: "Save Tasks",
    },
  },
  field,
};

export const post: ComponentSettings<typeof settings> = settings;
