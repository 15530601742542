import { Component, ViewChild, Output, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { getApp } from "../app";

@Component({
  selector: "app-override-mpnFields",
  templateUrl: "./override-mpnFields-modal.component.html",
  styleUrls: ["./override-mpnFields-modal.component.scss"],
})
export class OverrideMpnFieldsModalComponent implements OnInit {
  @ViewChild("overrideMpnFields", { static: false })
  overrideMpnFields: any;
  @Output()
  ok = new Subject<any>();

  app = getApp((app) => {
    this.app = app;
  });

  constructor(private modalService: NgbModal, private router: Router) {}

  ngOnInit() {
    this.app.overrideMpnFields.manufacturerKeys =
      this.app.list.RM.manufacturerMappingFields;
    this.app.overrideMpnFields.partKeys = this.app.list.RM.partMappingFields;
  }

  async open() {
    try {
      const result = await this.modalService.open(this.overrideMpnFields, {
        windowClass: "mediumModal",
        backdrop: "static",
      }).result;
      this.ok.next(result);
    } catch (err) {
      // NOP
    }
  }

  async closeModal() {
    this.app.overrideMpnFields.openOverrideModal = false;
    // reset partsToVehicles data to avoid the generation of the open impacts on the case
    await this.app.treeRow.generateImpactsFromParts(
      this.app.manufacturer.selectedPart["partNumber"]
    );

    // when case is created from alert both override modals needs to be opened
    if (
      this.app.thread.isCreatedFromAlert &&
      this.app.overrideMpnFields.selectedField ===
        this.app.fieldId.thread.artNumber
    ) {
      this.app.thread.modalSubject.next("mpnModal");
      this.app.thread.isCreatedFromAlert = false;
    }

    this.modalService.dismissAll();
  }
}
