import * as XLSX from "xlsx";
import { Type } from "../../../../shared/components";
import { AppType } from "../app.type";
import { getDocsByType, getImpacts } from "../api.service";
import { Post } from "../../../../shared/models/post";
import { Customer } from "../../../../shared/types/customers";
import { Impact } from "../../../../shared/models/impact";
import { objForEach } from "../../../../shared/object.utils";
import { saveAs } from "file-saver-es";
import { Thread } from "../../../../shared/models/thread";

function createSheet(app: AppType, type: Type) {
  const json: any[] = [];
  const docs = app.docs.getDocs(type);

  objForEach(docs, (doc, id) => {
    json.push(docToRow(id, doc));
  });

  return XLSX.utils.json_to_sheet(json, {
    skipHeader: false,
  });
}
export const exportTypes = {
  "All Cases": {
    text: {
      de: "Alle Fälle",
      en: "All Cases",
      nl: "All Cases",
    },
  },
  "ILS-003": {
    text: {
      de: "ILS-003 : Obsolescence Impact Analysis",
      en: "ILS-003 : Obsolescence Impact Analysis",
      nl: "ILS-003 : Obsolescence Impact Analysis",
    },
  },
  "ILS-017": {
    text: {
      de: "ILS-017 : Consumables Procurement Plan",
      en: "ILS-017 : Consumables Procurement Plan",
      nl: "ILS-017 : Consumables Procurement Plan",
    },
  },
  "ILS-018": {
    text: {
      de: "ILS-018 : Spare Procurement Plan",
      en: "ILS-018 : Spare Procurement Plan",
      nl: "ILS-018 : Spare Procurement Plan",
    },
  },
  "ILS-019": {
    text: {
      de: "ILS-019: Spare Part Analysis",
      en: "ILS-019: Spare Part Analysis",
      nl: "ILS-019: Spare Part Analysis",
    },
  },
  "All Cases json": {
    text: {
      de: "All Cases json",
      en: "All Cases json",
      nl: "All Cases json",
    },
  },
  "All Anzeigen": {
    text: {
      de: "All Anzeigen",
      en: "All posts",
      nl: "All posts",
    },
  },
};

function docToRow(id: string, doc: any) {
  const row: any = {};
  row.id = JSON.stringify(id);
  Object.keys(doc).forEach((key) => {
    row[key] = JSON.stringify(doc[key]);
  });
  return row;
}

function createBook(app: AppType) {
  const book = XLSX.utils.book_new();

  app.types.forEach((type) => {
    const sheet = createSheet(app, type);
    XLSX.utils.book_append_sheet(book, sheet, type);
  });

  return book;
}

export function writeFile(app: AppType) {
  const book = createBook(app);
  XLSX.writeFile(book, "export.xlsx");
}

/** append list of impacts to the content */
export async function writeFileSpecialLogic(app: AppType, type: string) {
  app.lock();
  /** get the list of impacts in order to be able to set them on the excel row - > thread -> impact */
  if (app.customers.expectCurrent !== Customer.DB) {
    app.impacts.impacts = await getImpacts(app.customers.expectCurrent);
  }
  if (app.thread.exportType !== "All Cases json") {
    await app.post.getAcceptedSCompressed();
  }
  if (app.thread.exportType === "All Cases json") {
    app.post.posts = (await getDocsByType(
      app.customers.expectCurrent,
      "post"
    )) as Post[];

    app.post.filterPosts();
    const cases = exportCasesJson(app);
    const file = new File([JSON.stringify(cases)], "All_Cases.json", {
      type: "data:application/json;charset=UTF-8",
    });
    saveAs(file);
  } else {
    const book = getBookWithDocs(app, type);
    let name = "";
    name = type != null ? getName(type, app) : new Date().toISOString();

    XLSX.writeFile(book, name);
    app.thread.exportType = "All Cases";
  }
  app.unlock();
}

function getName(type: string, app: AppType) {
  switch (type) {
    case "All Cases":
      return (
        new Date().toISOString().slice(0, 10) +
        `_${app.text.app.allCases}` +
        ".xlsx"
      );
    case "ILS-017":
    case "ILS-003":
    case "ILS-018":
    case "ILS-019":
      return (
        new Date().toISOString().slice(0, 10) +
        `_${type}` +
        `_${app.text.app.allCases}` +
        ".xlsx"
      );
    default:
      return new Date().toISOString().slice(0, 10) + "_LCM Client" + ".xlsx";
  }
}

/** the type of the document can be converted to TYPE or any in case if other document types need to be appended to the content */
function getBookWithDocs(app: AppType, type: string) {
  const book = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(book, getSheetWithDocs(app, type));
  return book;
}

/** prepare values directly from the existing docs - not using buffer functions */
function getSheetWithDocs(app: AppType, type: string) {
  let docs = app.thread.threads as Thread[];
  if (docs.length !== app.thread.ids.length) {
    docs = app.thread.threads.filter((t: Partial<Thread>) =>
      app.thread.ids.includes(t._id)
    );
  }
  const fields = getFieldsByExportType(app, type);
  const data = [
    fields.map((field) => getFieldLabel(field, app)),
    ...docs.map((doc: any) =>
      fields.map((field) => {
        if (
          (field.includes("thread.") ||
            field.includes("post.") ||
            field.includes("impact.")) &&
          field !== "impact.artNumber"
        ) {
          field = field.split(".")[1];
        }
        const result = app.thread.getValueOfTheField(field, doc);
        return result;
      })
    ),
  ];

  const worksheet = XLSX.utils.json_to_sheet(data, {
    skipHeader: true,
    cellStyles: true,
    dateNF: "yyyy-mm-dd, hh:mm:ss",
  });

  // set width cell in "characters"
  const columnWidth = 20;
  const wscols = data[0]
    ? Object.keys(data[0]).map(() => ({ wch: columnWidth }))
    : [];
  worksheet["!cols"] = wscols;

  return worksheet;
}
function getFieldsByExportType(app: AppType, type: string) {
  let fields: string[] = [];
  switch (type) {
    case "ILS-003":
      fields = app.list.thread.exportILS003;
      break;
    case "ILS-017":
      fields = app.list.thread.exportILS017;
      break;
    case "ILS-018":
      fields = app.list.thread.exportILS018;
      break;
    case "ILS-019":
      fields = app.list.thread.exportILS019;
      break;
    case "All Cases":
      fields = app.list.thread.csvExportColumns;
      break;
    case "ALL-Cases":
      fields = app.list.thread.csvExportColumns;
      break;
  }
  return fields;
}

function getFieldLabel(field: string, app: AppType) {
  const fieldType = field.includes(".") ? field.split(".")[0] : field;
  switch (fieldType) {
    case "thread":
    case "post":
      if (field === "post.resolveClass") {
        return app.text.post.solutions;
      }
      if (field === "thread._id") {
        return app.text.thread.caseLink;
      } else {
        return app.field.getLabel(field);
      }
    case "impact":
      if (app.customers.expectCurrent === Customer.NS) {
        if (field === "impact.omfVehicleName") {
          return app.text.thread.trainseries;
        }
      } else {
        return app.field.getLabel(field);
      }
      break;
    default:
      return field;
  }
}

// Add posts and impacts to corresponding thread and change keys into labels
function exportCasesJson(app: AppType) {
  const casesForExport: any[] = [];
  app.thread.threads.forEach((thread: any) => {
    delete thread._id;
    thread = changeObjectKeysToLabels(app, thread);
    thread.posts = [];
    thread.impacts = [];
    app.post.posts.forEach((post: Partial<Post>) => {
      delete post._id;
      if (post.omfNumber === thread[app.field.getLabel("thread.omfNumber")]) {
        post.type = "post";
        post = changeObjectKeysToLabels(app, post);
        thread.posts.push(post);
      }
    });
    app.impacts.impacts.forEach((impact: Impact) => {
      delete impact._id;
      delete impact._rev;
      if (impact.omfNumber === thread[app.field.getLabel("thread.omfNumber")]) {
        impact.type = "impact";
        impact = changeObjectKeysToLabels(app, impact);
        thread.impacts.push(impact);
      }
    });
    casesForExport.push(thread);
  });
  return casesForExport;
}

function changeObjectKeysToLabels(app: AppType, doc: any): any {
  const type: Type = doc.type;

  // Get existing fields for which we have labels
  const existingPostFields: any = new Set(
    Object.keys(app.settings[type].field)
  );

  // Remove the properties for which we do not have labels like _id, _rev
  let keys = Object.keys(doc);
  keys = keys.filter((name) => {
    if (!existingPostFields.has(name)) {
      delete doc[name];
    }
    return existingPostFields.has(name);
  });

  // Create a doc with the new value of the key
  let newDoc = {};
  Object.keys(doc).forEach((key) => {
    const newPair = { [app.field.getLabel(type + "." + key)]: doc[key] };
    newDoc = { ...newDoc, ...newPair };
  });
  return newDoc;
}
